<template>
  <v-treeview activatable :items="items"></v-treeview>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        id: 1,
        name: "Главный вход",
        children: [{ id: 2, name: "Второй этаж" }],
      },
      { id: 3, name: "Одиночный вход" },
    ],
  }),
};
</script>
